import "./Milestones.css";
import img1 from "../../Assets/—Pngtree—golden wheat ears wheat wheat_3925542.png";
export default function Milestones() {
  return (
    <>
      <section className="milestonessection">
        <div className="wheatimagediv">
          <img src={img1} alt="" />
        </div>
        <div className="milestonediv">
          <h1>Strategic</h1>
          <h1>Milestones</h1>

          <p>
            BSH Agronomy Private Limited aims to establish itself as a leader in
            the agronomy sector through a series of strategic milestones
            designed to drive growth and sustainability. Our initial focus is on
            building a strong foundation by setting up our headquarters and
            operational facilities, recruiting a team of agronomy experts, and
            developing our initial crop production and market gardening
            projects. These efforts will enable us to create a robust
            infrastructure that supports our long-term goals and positions us
            for success in the competitive agricultural market.
          </p>

          <p>
            To expand our operations and enhance our impact, we will diversify
            the variety of crops we grow and scale up our market gardening
            activities. By leveraging advanced agronomic techniques and
            cutting-edge technology, we will improve crop yields and quality,
            ensuring that our produce meets the highest standards. Additionally,
            we will establish partnerships with local farmers and agricultural
            cooperatives to share knowledge, resources, and best practices,
            fostering a collaborative approach to sustainable agriculture.
          </p>

          <p>
            Education and empowerment are at the core of our mission. We will
            develop comprehensive training programs and resources to help
            farmers adopt innovative farming practices and improve their
            productivity. By organizing workshops, seminars, and hands-on
            training sessions, we aim to build a community of knowledgeable and
            skilled agriculturists who can contribute to the overall growth and
            development of the agronomy sector in India. Our commitment to
            farmer education will not only enhance their livelihoods but also
            ensure the long-term sustainability of our agricultural ecosystem.
          </p>

          <p>
            Looking ahead, our vision is to expand our reach and impact on a
            national and eventually global scale. We plan to invest in research
            and development to continually innovate and improve our farming
            techniques. By exploring new markets and distribution channels, we
            aim to increase our market presence and ensure that our high-quality
            produce reaches a broader audience. Through these strategic
            milestones, BSH Agronomy Private Limited is dedicated to creating a
            thriving agricultural ecosystem that meets the food demands of today
            and ensures food security for future generations.
          </p>
        </div>
      </section>
    </>
  );
}
