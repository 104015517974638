import BenefitCard from '../BenefitCard/BenefitCard';
import { benefitData } from '../../Utils/StaticData';
export default function Benefits() {
  return (
    <>
       <div class="container-fluid bg-light bg-icon py-6">
        <div class="container">
            <div class="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxwidth: "500px"}}>
                <h1 class="display-5 mb-3"  style={{fontWeight:"700"}}>Product Benefits</h1>
                <p>Discover the unparalleled benefits of products, meticulously designed to enhance your daily life.</p>
            </div>
            <div class="row g-4">
              {
                benefitData.map((benefit,index)=>(
                  <BenefitCard benefit={benefit}/>
                ))
              }
            </div>
        </div>
    </div>
    </>
  )
}
