import React from 'react'

export default function ProductDetailSection({product}) {
  return (
    <>
    <section id="service-details" className="service-details bg-icon">
      <div className="container">
        <div className="row gy-4">

          <div className="col-lg-5 d-flex align-items-center">
            <img src={product && product.imageUrl} alt="" className="img-fluid services-img"/>
          </div>

          <div className="col-lg-7">
            <h3>{product && product.data && product.data.name}</h3>
            <p>
              {product && product.data && product.data.description}
            </p>
            {/* <h3>Compositions</h3>
            <ul>
              {
                product && product.data && product.data.composition && JSON.parse(product.data.composition).map((composition)=>(
                  <li><i className="bi bi-check-circle"></i> <span>{composition}</span></li>
                ))
              }
              
            </ul> */}

            <h3>Advantages</h3>
            <ul>
              {
                product && product.data && product.data.advantages && JSON.parse(product.data.advantages).map((advantage)=>(
                  <li><i className="bi bi-check-circle"></i> <span>{advantage}</span></li>
                ))
              }
              
            </ul>

            <h3>Dosage and Application</h3>
            <p>
             {product && product.data && product.data.applications}
            </p>

            <h3>Crops</h3>
            <p>
             {product && product.data && product.data.crops}
            </p>

          </div>

        </div>

      </div>
    </section>
    </>
  )
}
