import React, { useState } from 'react';

const ReadMore = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <p>
        {isExpanded ? children : ""}
        <button onClick={toggleReadMore} style={{ marginLeft: '10px', color: 'blue', border: 'none', background: 'none', cursor: 'pointer', textDecoration: 'underline' }}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      </p>
    </div>
  );
};

export default ReadMore;
