import "./Infrastructure.css";
import infrastructure1 from "../../Assets/infrastructure1.jpeg";

export default function Infrastructure() {
  return (
    <>
      <section className="infrastructuresection">
        <div className="infrastrustureheading">
          <h1>Infrastructure</h1>
        </div>

        <div className="infrstructurecontent">
          <p>
            Our company operates four cutting-edge manufacturing units that are
            equipped with state-of-the-art machinery. These facilities are
            highly efficient capable of producing an impressive annual output of
            180000 metric tons. This ensures we meet high demand and serve a
            vast customer base. Overall, our well equipped and technologically
            advanced units from a strong base for achieving production targets
            and meeting market needs. Additionally, we well-equipped
            laboratories for quality control of both raw materials and finished
            products.
          </p>

          <h5>We have following Laboratory facilities.</h5>

          <ul>
            <li>Microbiology laboratory</li>
            <li>Chemical laboratory</li>
          </ul>

          <div className="infrastructureimagesdiv">
            <img src={infrastructure1} alt="" />
            <img src={infrastructure1} alt="" />
            <img src={infrastructure1} alt="" />
            <img src={infrastructure1} alt="" />
            <img src={infrastructure1} alt="" />
          </div>
        </div>

        <div className="infrastrustureheading">
          <h1>Crafting Excellence</h1>
        </div>

        <div className="infrstructurecontent">
          <p>Our Quality-Driven Commitment and Value-Centric Approach</p>

          <p>
            Our unwavering commitment to the environment and nation compels us
            to prioritize sustainability and innovation in everything we do. We
            believe that true excellence comes from a relentless focus on
            quality, ethical practices, and a deep understanding of our
            customers' needs. Our approach includes rigorous quality control
            measures, continuous improvement, and a dedication to reducing our
            environmental impact through sustainable practices and responsible
            resource management. From sourcing raw materials to final product
            delivery, every step of our process is designed to be
            environmentally friendly, ensuring minimal waste, reduced carbon
            emissions, and the promotion of renewable energy sources.
          </p>

          <p>
            Customer satisfaction is our top priority. We strive to exceed
            expectations by providing exceptional service and value, listening
            to feedback, and working collaboratively to develop solutions that
            truly benefit our customers. Our commitment to ethical practices,
            transparency, and community engagement ensures that we build
            long-lasting relationships based on trust and mutual respect. By
            prioritizing quality, sustainability, innovation, and customer
            satisfaction, we are not only building a successful business but
            also contributing to a better future for our environment and
            society.
          </p>
        </div>
      </section>
    </>
  );
}
