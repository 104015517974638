import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBZNOVtrLIK-2yNuMFX2tGGh4L4PbF-jnA",
  authDomain: "bsh-agronomy.firebaseapp.com",
  databaseURL: "https://bsh-agronomy-default-rtdb.firebaseio.com",
  projectId: "bsh-agronomy",
  storageBucket: "bsh-agronomy.appspot.com",
  messagingSenderId: "642497939594",
  appId: "1:642497939594:web:506b1a9ec966a2f30fc573"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export { db , storage, auth};
export default firebaseConfig;
