import BrochureAbout from "../../Components/BrochureAbout/BrochureAbout";
import BrochureFooter from "../../Components/BrochureFooter/BrochureFooter";
import BrochureHeader from "../../Components/BrochureHeader/BrochureHeader";
import BrochureProductDetails from "../../Components/BrochureProductDetails/BrochureProductDetails";
import BrochureProducts from "../../Components/BrochureProducts/BrochureProducts";
import ChairmanMessage from "../../Components/ChairmanMessage/ChairmanMessage";
import Infrastructure from "../../Components/Infrastructure/Infrastructure";
import Milestones from "../../Components/Milestones/Milestones";
import Presence from "../../Components/Presence/Presence";

export default function Brochure() {
  return (
    <>
      <BrochureHeader/>
      <BrochureAbout/>
      <Infrastructure/>
      <BrochureProducts/>
      <Milestones/>
      <ChairmanMessage/>
      <Presence/>
      <BrochureProductDetails/>
      <BrochureFooter/>
    </>
  )
}
