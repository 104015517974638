import HeroSection from '../../Components/HeroSection/HeroSection'
import AboutUs from "../../Components/AboutUs/AboutUs";
import Benefits from "../../Components/Benefits/Benefits";
// import Client from "../../Components/Client/Client";
import ProductSection from "../../Components/ProductSection/ProductSection";
import Testimonial from "../../Components/Testimonial/Testimonial";
import Parteners from '../../Components/Partners/Partners';
import FloatingButton1 from '../../Components/FloatingButton1/FloatingButton1';
// import Locations from '../../Components/Locations/Locations';
import LocationsMap from '../../Components/LocationsMap/LocationsMap';
export default function Home() {
  return (
    <>
    <HeroSection/>
    <AboutUs/>
    {/* <Locations/> */}
    <LocationsMap/>
    {/* <Client/> */}
    <ProductSection/>
    <Benefits/>
    <Testimonial/>
    <Parteners/>
    <FloatingButton1/>
    </>
  )
}
