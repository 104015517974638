import { FaWhatsapp } from "react-icons/fa";
import "./FloatingButton1.css";
import { whatsApp } from "../../Utils/StaticData";
export default function FloatingButton1() {

  return (
    <>
      <div className="maindivstickytop">
        <div className="maindivsticky1">
          <a href={whatsApp}><FaWhatsapp style={{ fontSize: "30px", color: "white" }} /></a>
        </div>
      </div>
    </>
  );
}
