import { useState, useEffect, useRef } from "react";
import "./ProductDetail.css";
import Top from "../../Components/Top/Top";
import FetchDataById from "../../Utils/FetchDataById";
import { useParams } from "react-router-dom";
import BuyNowForm from "../../Components/BuyNowForm/BuyNowForm";
import { IoMdClose } from "react-icons/io";

export default function ProductDetail() {
  const { id } = useParams();

  const product = FetchDataById("Product", id);

  const [model, setmodel] = useState(false);
  const modelRef = useRef(null);

  const openModel = () => {
    setmodel(!model);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modelRef.current && !modelRef.current.contains(event.target)) {
        setmodel(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [modelRef]);

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <BuyNowForm ref={modelRef} />
        <button
          className="modelcloseButton"
          onClick={() => {
            setmodel(false);
          }}
        >
          <IoMdClose />
        </button>
      </div>

      <Top heading="Product Details" />

      <section id="service-details" className="service-details bg-icon">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 d-flex align-items-center" >
              <img
                src={product && product.imageUrl}
                alt=""
                className="img-fluid services-img"
              />
            </div>

            <div className="col-lg-7">
              <h3>{product && product.data && product.data.name}</h3>
              <p>{product && product.data && product.data.description}</p>
              {product && product.data && product.data.composition && <h3>Compositions</h3>}
              <ul>
                {product &&
                  product.data &&
                  product.data.composition &&
                  JSON.parse(product.data.composition).map((composition) => (
                    <li>
                      <i className="bi bi-check-circle"></i>{" "}
                      <span>{composition}</span>
                    </li>
                  ))}
              </ul>

              <h3>Advantages</h3>
              <ul>
                {product &&
                  product.data &&
                  product.data.advantages &&
                  JSON.parse(product.data.advantages).map((advantage) => (
                    <li>
                      <i className="bi bi-check-circle"></i>{" "}
                      <span>{advantage}</span>
                    </li>
                  ))}
              </ul>

              <h3>Dosage and Application</h3>
              <p>{product && product.data && product.data.applications}</p>

              <h3>Crops</h3>
              <p>{product && product.data && product.data.crops}</p>

              <div className="d-flex align-items-center justify-content-end">
                <button
                  className="buynowbtn btn rounded-pill py-3 px-5"
                  onClick={openModel}
                >
                  Buy Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
