import { Link } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import './ProductSection.css';
import fetchData from '../../Utils/FetchData';
export default function ProductSection() {

  const product = fetchData("Product");

  console.log("Product Data",product);
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="section-header text-center mx-auto mb-5" data-wow-delay="0.1s"style={{maxwidth: "500px"}}>
            <h1 class="display-5 mb-3" style={{fontWeight:"700"}}>Our Products</h1>
            <p>
            We pride ourselves on offering products that embody excellence and innovation. Our range is crafted to meet the highest standards, ensuring durability and reliability. 
            </p>
          </div>
            <div class="tab-content">
                <div id="tab-1" class="tab-pane fade show p-0 active">
                    <div class="row g-4">
                        {
                          product && product.length > 0 &&
                          product.map((product)=>(
                            <ProductCard product={product}/>
                          ))
                        }
                        <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                            <Link class="aboutusbtn btn rounded-pill py-3 px-5" to="/products">Browse More Products</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
