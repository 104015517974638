import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

import "leaflet/dist/leaflet.css";
import {osm} from "../../Utils/StaticData";

import cities from "../../Utils/Cities.json";
import sampleVideo from '../../Assets/Video/bsh.mp4'; 

const markerIcon = new L.Icon({
  iconUrl: require("../../Assets/marker.png"),
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});


export default function LocationsMap() {
    const [center] = useState({ lat: 23.5937, lng: 82.9629});
    const ZOOM_LEVEL = 5;
    const mapRef = useRef();
  return (
    <>
        <div className="row">
        <div className="col text-center">
        <div class="section-header text-center mx-auto mb-5" data-wow-delay="0.1s"style={{maxwidth: "500px"}}>
            <h1 class="display-5 mb-3" style={{fontWeight:"700"}}>Office Locations</h1>
            <p>Our prime location offers convenient access to major transportation hubs, making your commute effortless whether you’re arriving by car, train, or bus.</p>
          </div>
          <div className="col">
          <div style={{ 
      position: "relative", 
      paddingBottom: "56.25%", /* 16:9 aspect ratio */
      height: "0", 
      overflow: "hidden",
      zIndex: "998"
    }}>
      <video 
        src={sampleVideo} 
        autoPlay 
        loop 
        muted 
        controls 
        style={{ 
          position: "absolute", 
          top: "0", 
          left: "0", 
          width: "100%", 
          height: "80%", 
          objectFit: "cover" 
        }}
      />
    </div>
            {/* <MapContainer
                          center={center}
                          zoom={ZOOM_LEVEL}
                          ref={mapRef}
                          style={{ height: "700px", width: "100%",zIndex:"998" }}
                          dragging={false}
                          touchZoom={false}
                          scrollWheelZoom={false}
                          doubleClickZoom={false}
                          boxZoom={false}
                          keyboard={false}
                          zoomControl={false}
            >
              <TileLayer
                url={osm.maptiler.url}
                attribution={osm.maptiler.attribution}
              />

              {cities.map((city, idx) => (
                <Marker
                  position={[city.lat, city.lng]}
                  icon={markerIcon}
                  key={idx}
                >
                  <Popup>
                    <b>
                      {city.city}, {city.country}
                    </b>
                  </Popup>
                </Marker>
              ))}
            </MapContainer> */}
          </div>
        </div>
      </div>
    </>
  )
}
