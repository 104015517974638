import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FetchData from "../../Utils/FetchData";
import TestimonialCard from "../TestimonialCard/TestimonialCard";

export default function Testimonial() {
  const settings = {
    infinite: true,
    speed: 500,
    loop: true,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testimonialData =  FetchData("Testimonial");

  console.log("Testimonial data",testimonialData);

  return (
    <>
      <div class="container-fluid bg-light py-6">
        <div class="container">
          <div
            class="section-header text-center mx-auto mb-5"
            data-wow-delay="0.1s"
            style={{maxwidth: "500px"}}
          >
            <h1 class="display-5 mb-3" style={{fontWeight:"700"}}>Customer Review</h1>
            <p>
            Our clients' success is our success. Hear what our satisfied customers have to say about
            their experiences with BSH Agronomy Pvt. Ltd.
            </p>
          </div>
          <div className="testimonialrow">
            
          <Slider {...settings} className="testimonialslider">
            {testimonialData.map((testimonial, index) => (
             <TestimonialCard key={index} testimonial={testimonial} />
            ))}
          </Slider>

          </div>
        </div>
      </div>
    </>
  );
}
