import { useEffect } from 'react';
import './Partners.css'
// import brand1 from '../../Assets/Brands/3m.svg';
// import brand2  from '../../Assets/Brands/barstool-store.svg';
// import brand3 from '../../Assets/Brands/forbes.svg';
// import brand4 from '../../Assets/Brands/macys.svg';
// import brand5 from '../../Assets/Brands/menshealth.svg';

import { FaStarOfLife } from "react-icons/fa";
import fetchData from '../../Utils/FetchData';

export default function Parteners() {
    useEffect(()=>{
        var copy = document.querySelector(".logos-slide").cloneNode(true);
        document.querySelector(".logos").appendChild(copy);
    },[]);

    const brand =  fetchData("Brand");

    console.log("brand data",brand);

  return (
    <>
    <div class="logos">
      <div class="logos-slide">
        <div><FaStarOfLife/><h2 data-text="Organic">Organic</h2></div>
        <div><FaStarOfLife/><h2 data-text="Agriculture">Agriculture</h2></div>
        <div><FaStarOfLife/><h2 data-text="Farming">Farming</h2></div>
      
      {/* {
          brand && brand.length > 0 ?
          brand.map((brand)=>(
            <img key={brand.id} src={brand.imageUrl} alt='brand'/>
          ))
          :
          <>
          <img src={brand1} alt='brand'/>
          <img src={brand2} alt='brand'/>
          <img src={brand3} alt='brand'/>
          <img src={brand4} alt='brand'/>
          <img src={brand5} alt='brand'/>
          </>
      }  */}
      </div>
    </div>
    </>
  )
}
