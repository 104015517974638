import Top from "../../Components/Top/Top";
import ProductCard from "../../Components/ProductCard/ProductCard";
import FetchData from "../../Utils/FetchData";
export default function Products() {
  const product = FetchData("Product");

  console.log("Product Data",product);
  return (
    <>
      <Top heading="Products" />
      <div class="container-xxl py-5">
        <div class="container">
          <div class="tab-content">
            <div id="tab-1" class="tab-pane fade show p-0 active">
              <div class="row g-4">
              {
                product && product.length > 0 &&
                product.map((product)=>(
                <ProductCard product={product}/>
                ))
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
