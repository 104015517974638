import { useEffect,useState } from 'react';
import logoimage from '../../Assets/BSH AGRONOMY LOGO PNG.png';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import './Header.css';
import { Link, NavLink} from 'react-router-dom';
import { mobile, mobile2 } from '../../Utils/StaticData';
import { email } from '../../Utils/StaticData';

import { facebook } from '../../Utils/StaticData';
import { instagram } from '../../Utils/StaticData';
import { twitter } from '../../Utils/StaticData';
import { linkedin } from '../../Utils/StaticData';
// import homeimg from '../../Assets/home.png';
// import aboutimg from '../../Assets/aboutus.png';
// import productimg from '../../Assets/product.png';
// import contactimg from '../../Assets/contact.png';


export default function Header () {
    const [showNavbar, setShowNavbar] = useState(false);

    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth < 992) {
                if (window.scrollY > 45) {
                    document.querySelector('.fixed-top').classList.add('bg-white', 'shadow');
                } else {
                    document.querySelector('.fixed-top').classList.remove('bg-white', 'shadow');
                }
            } else {
                if (window.scrollY > 45) {
                    document.querySelector('.fixed-top').classList.add('bg-white', 'shadow');
                    document.querySelector('.fixed-top').style.top = '-45px';
                } else {
                    document.querySelector('.fixed-top').classList.remove('bg-white', 'shadow');
                    document.querySelector('.fixed-top').style.top = '0';
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="container-fluid0 fixed-top">
        <div className="top-bar row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
                <small><i className="fa fa-phone-alt me-3"></i>{mobile}</small>
                <small className="ms-4"><i className="fa fa-phone-alt me-3"></i>{mobile2}</small>
                <small className="ms-4"><i className="fa fa-envelope me-2"></i>{email}</small>
            </div>
            <div className="col-lg-6 px-5 text-end">
                <a className="text-body ms-3" href={facebook}><i className="fab fa-facebook-f"></i></a>
                <a className="text-body ms-3" href={twitter}><i className="fab fa-twitter"></i></a>
                <a className="text-body ms-3" href={linkedin}><i className="fab fa-linkedin-in"></i></a>
                <a className="text-body ms-3" href={instagram}><i className="fab fa-instagram"></i></a>
            </div>
        </div>

    <div className="navbarcontainer">
      <div className="col-xl-6 col-6">
         <Link to="/"><img className='logoimg' src={logoimage} alt="logo" /></Link>
      </div>

      <div className="menu-icon col-xl-0 col-lg-0 col-6" onClick={handleShowNavbar}>
        {showNavbar ? <IoClose/> : <GiHamburgerMenu/>}
      </div>

      <div className={`nav-elements  ${showNavbar && "active"} col-xl-6 col-6 ` }>
        <ul>
          <li>
            <NavLink to="/" onClick={handleShowNavbar}>Home</NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={handleShowNavbar}>About</NavLink>
          </li> 
          <li>
            <NavLink to="/products" onClick={handleShowNavbar}>Products</NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={handleShowNavbar}>Contact</NavLink>
          </li>
          <li className='loginlink'>
            <NavLink to="/login" onClick={handleShowNavbar}><FaUser/></NavLink>
          </li>
          
        </ul>
      </div>

    </div>
    </div>
    )
};