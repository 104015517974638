import client from '../Assets/gardener.png';
import products from '../Assets/commodity.png';
import retailer from '../Assets/seedling.png';
import location from '../Assets/location.png';
import village from '../Assets/tea.png';
import production from '../Assets/commodities.png';

import natural from '../Assets/icon-1.png';
import quality from '../Assets/icon-2.png';
import affordable from '../Assets/icon-3.png';

export const address = "Plot. No.142, Nana Borsara Road, Tal. Mangrol, Dist. Surat 394125";

export const mobile  = "+91 72260 07834"

export const mobile2 =  "+91 72278 30331"

export const whatsApp = "https://wa.me/7226007834"

export const email  = 'Info@bshagronomy.com'

export const instagram = "https://www.instagram.com/bshagronomy?igsh=MWRmbXc1aDI4dDV5eQ%3D%3D&utm_source=qr"

export const facebook = "https://www.facebook.com/bshagronomy?mibextid=LQQJ4d"

export const twitter = "https://x.com/bshagronomy/"

export const linkedin = "https://www.linkedin.com/company/bsh-agronomy-pvt-ltd/"

export const API = "http://localhost:5500"

export const osm =  {
    maptiler: {
        url:
            "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?key=uJwhEh4mknO6nC8m86gu",
        attribution:
            '© <a href="https://stadiamaps.com/">Stadia Maps</a>, © <a href="https://openmaptiles.org/">OpenMapTiles</a> © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
    },
};

export const counterData = [
    {
        image:production,
        title :"Production Capacity(Mt/ton Annually)",
        count : "180000"
    },
    {
        image:client,
        title :"Happy Clients",
        count : "120000"
    },
    {
        image:products,
        title :"Products",
        count : "15"
    },
    {
        image:location,
        title :"States",
        count : "20"
    },
    {
        image:retailer,
        title :"Village Network",
        count : "3000"
    },
    {
        image:village,
        title :"Branches",
        count : "500"
    },
]

export const benefitData = [
    {
        image:natural,
        title:"Natural Process",
        description:" Our commitment to sustainability and eco-friendliness means that each product is crafted with carefully sourced, natural ingredients and materials, ensuring minimal environmental impact."
    },
    {
        image:quality,
        title:"Quality",
        description:"Our products are meticulously crafted using the finest materials and state-of-the-art technology to ensure exceptional performance and durability."
    },
    {
        image:affordable,
        title:"Affordable",
        description:"We are committed to offering exceptional value at affordable prices. Our products are designed to deliver outstanding performance and durability without breaking the bank"
    },
]