import "./TestimonialCard.css";
import profileIcon from '../../Assets/Profile.png';

export default function TestimonialCard({testimonial}) {
  return (
    <>
      <div class="testimonial-item position-relative bg-white p-5 mt-4">
            <i class="fa fa-quote-left fa-3x position-absolute top-[-10] start-0 mt-n4 ms-5" style={{top: "-20px",color:"var(--secondary)"}}></i>
            <p class="mb-4">{testimonial.data.heading}</p>
            <p class="mb-4">{testimonial.data.description}</p>
            <div class="d-flex align-items-center">
                <img class="testimonialImage flex-shrink-0 rounded-circle" src={profileIcon} alt=""/>
                <div class="ms-3">
                    <h5 class="mb-1">{testimonial.data.name}</h5>
                    <span>{testimonial.data.designation}</span>
                </div>
            </div>
      </div>
    </>
  );
}
