import crop from "../../Assets/brochureheader.jpg";
import "./BrochureAbout.css";

export default function BrochureAbout() {
  return (
    <>
      <section className="brochureaboutsection bg-icon">
        <div className="brochureaboutdiv">
          <div className="brochureaboutimgdiv">
            <img src={crop} alt="" />
          </div>

          <div className="brochureaboutdescdiv">
            <h1 className="brochureheading">About Us</h1>
            <p>
              <span>BSH Agronomy </span>Private Limited, incorporated on October
              30, 2020, is a distinguished entity classified as a non-government
              company. Registered at the Registrar of Companies in Ahmedabad,
              BSH Agronomy is dedicated to advancing the agricultural sector.
              Our core activities involve the growing of crops, market
              gardening, and horticulture, reflecting our commitment to
              enhancing food production and contributing to sustainable
              agriculture.
            </p>

            <p>
              At BSH Agronomy, we prioritize innovation and sustainability in
              our farming practices. By leveraging advanced agronomic techniques
              and cutting-edge technology, we aim to improve crop yields and
              quality while promoting environmental stewardship. Our dedicated
              team of experts works tirelessly to support the agricultural
              community, ensuring that we meet the evolving needs of our
              customers and contribute to the overall growth and development of
              the agronomy sector in India.
            </p>

            <p>
              In addition to our core activities, BSH Agronomy Private Limited
              is committed to empowering farmers through education and support.
              We provide training programs and resources to help farmers adopt
              best practices in market gardening and horticulture. By fostering
              a community of knowledgeable and skilled agriculturists, we aim to
              enhance productivity and sustainability across the agricultural
              landscape. Our vision is to create a thriving agricultural
              ecosystem that not only meets the food demands of today but also
              ensures food security for future generations.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
