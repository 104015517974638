import { useState } from 'react';
import CounterCard from '../CounterCard/CounterCard';
import './Counter.css';
import ScrollTrigger from 'react-scroll-trigger';
import { counterData } from '../../Utils/StaticData';
export default function Counter() {
  const [CounterOn,setCounterOn]=useState(false);

  return (
    <>
     <ScrollTrigger onEnter={()=>{setCounterOn(true)}} onExit={()=>{setCounterOn(false)}}>
      <section id="stats-counter" className="stats-counter">
       <div className="container">

        <div className="row gy-4">
          {
            counterData.map((counter,index)=>(
              <CounterCard key={index} counter={counter} CounterOn={CounterOn}/>
            ))

          }
        </div>

       </div>
      </section>
     </ScrollTrigger>
    
    </>
  )
}
