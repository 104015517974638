import { useState } from 'react';
import UploadData from '../../Utils/UploadData';

export default function UploadProduct({ setLoadingState, setLoadingMessage }) {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

 
  const [composition, setComposition] = useState([]);
  const [advantages, setAdvantages] = useState([]);

  const [applications, setApplications] = useState("");
  const [crops, setCrops] = useState("");

  const [compositionInput,setCompositionInput] = useState("");
  const [advantagesInput,setAdvantagesInput] = useState("");

  const handleAddComposition = () => {
    if (compositionInput) {
      setComposition([...composition, compositionInput]);
     setCompositionInput("");
    }
  };

  const handleRemoveComposition = (index) => {
    setComposition(composition.filter((_, i) => i !== index));
  };

  const handleAddAdvantage = () => {
    if (advantagesInput) {
      setAdvantages([...advantages, advantagesInput]);
      setAdvantagesInput("");
    }
  };

  const handleRemoveAdvantage = (index) => {
    setAdvantages(advantages.filter((_, i) => i !== index));
  };


  const handleSubmitProduct = async () => {
    console.log("composition",composition);
    const formData = new FormData();
    formData.set("name", name);
    formData.set("description", description);
    formData.set("applications",applications);
    formData.set("crops",crops);
    formData.set("composition", JSON.stringify(composition));
    formData.set("advantages", JSON.stringify(advantages));


    const data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }

    setLoadingState(true);
    setLoadingMessage("", "Please wait until your data is submitted!...");

    const { type, text, loading } = await UploadData({ collectionName: "Product", file, data });

    setLoadingMessage(type, text);
    setTimeout(() => {
      setLoadingState(loading);
    }, 5000);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <div>
      <div className="uploadimagerow">
        <div className="uploadimagecolumn">
          <div className="uploadimagecolumninnerdiv">
            <h3>Product Section: </h3>

            <label htmlFor="imageInput">Upload Image:</label>
            <input type="file" id="imageInput" accept="image/*" onChange={handleFileChange} required />

            <input
              type="text"
              id="productName"
              placeholder="Enter Product Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <label htmlFor="productdescription">Product Description</label>
            <textarea
              id="productdescription"
              placeholder="Enter Product Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <label htmlFor="producthowtouse">Applications</label>
            <textarea
              id="producthowtouse"
              placeholder="How To Use The Product"
              value={applications}
              onChange={(e) => setApplications(e.target.value)}
            />

            <label htmlFor="producthowtouse">Crops</label>
            <textarea
              id="producthowtouse"
              placeholder="Crops"
              value={crops}
              onChange={(e) => setCrops(e.target.value)}
            />


            <label htmlFor="productbenefits">Product composition</label>
            <input
              type="text"
              id="productbenefits"
              placeholder="Enter a Benefit"
              value={compositionInput}
              onChange={(e) =>setCompositionInput(e.target.value)}
            />
            <button className="uploadimagebtn" type="button" onClick={handleAddComposition}>Add Composition</button>
            <ul>
              {composition.map((composition, index) => (
                <li key={index}>
                  {composition} <br /> 
                  <button className="uploadimagebtn" type="button" onClick={() => handleRemoveComposition(index)}>Remove</button>
                </li>
              ))}
            </ul>

            <label htmlFor="productbenefits">Product Advantages</label>
            <input
              type="text"
              id="productbenefits"
              placeholder="Enter a Benefit"
              value={advantagesInput}
              onChange={(e) =>setAdvantagesInput(e.target.value)}
            />
            <button className="uploadimagebtn" type="button" onClick={handleAddAdvantage}>Add Advantage</button>
            <ul>
              {advantages.map((advantage, index) => (
                <li key={index}>
                  {advantage} <br /> 
                  <button className="uploadimagebtn" type="button" onClick={() => handleRemoveAdvantage(index)}>Remove</button>
                </li>
              ))}
            </ul>

            <button
              className="uploadimagebtn"
              onClick={handleSubmitProduct}
              style={{ marginTop: "5px", borderRadius: "5px" }}
            >
              Upload
            </button>
          </div>
        </div>

        <div className="uploadimagecolumn">
          <img className="uploadimageimage" src="/" alt="productsectionimage" />
        </div>
      </div>
    </div>
  );
}
