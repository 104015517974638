import "./Presence.css";
import presence from "../../Assets/bajra.png";

export default function Presence() {
  return (
    <>
      <section className="presencesection">
        <div className="presencecontent">
          <h1>Our Presence</h1>
          {/* <h1>Presence</h1> */}

          <p>
            BSH Agronomy Private Limited is headquartered in Surat, serving
            as the central hub for our diverse agricultural operations,
            including crop growing, market gardening, and horticulture. Our
            strategic location and strong local presence enable us to
            effectively coordinate with regional farmers and agricultural
            cooperatives, fostering collaboration and knowledge sharing.
            Additionally, our robust digital footprint allows us to provide
            valuable resources and updates through online platforms, ensuring
            continuous support and connectivity with the agricultural community.
            Committed to expanding our reach, we aim to grow both nationally and
            globally, increasing our market presence and contributing to
            sustainable farming practices and food security on a larger scale.
          </p>

          <div className="presencemapdiv">
            <p>We have 15+ products</p>

            <p>We are into more than 20+ states all over India.</p>

            <p>We have 2000 + retailers in PAN India</p>
          </div>
        </div>

        <div className="presenceimage">
          <img src={presence} alt="" />
        </div>
      </section>
    </>
  );
}
