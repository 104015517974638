export default function BenefitCard({benefit}) {
  return (
    <>
        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="bg-white text-center h-100 p-4 p-xl-5">
                <img class="img-fluid mb-4" src={benefit.image} alt=""/>
                <h4 class="mb-3">{benefit.title}</h4>
                <p class="mb-4" style={{textAlign:"center"}}>{benefit.description}</p>
                {/* <a class="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href="/">Read More</a> */}
            </div>
        </div>
    </>
  )
}
