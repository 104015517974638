import "./BrochureFooter.css";
import { address } from "../../Utils/StaticData";
import { mobile } from "../../Utils/StaticData";
import { email } from "../../Utils/StaticData";
import logo from '../../Assets/BSH AGRONOMY LOGO PNG.png';
import simple from '../../Assets/quality.png';
import certificate from '../../Assets/bsh agronomy private limited iso-9001-2015_page-0001.jpg';

export default function BrochureFooter() {
  return (
    <>
      <section className="brochurefootersection bg-icon">
        <div className="brochurefooterdiv">
            <div className="col-sm-12 col-lg-12 herosectioninnerdiv3" >
                <div className="brochurelogodiv" style={{backgroundColor:"white"}}>
                <img className='brochurelogo' src={logo} alt="" />
                </div>
                <img className='qualityImage' src={simple} alt="" />
            </div>

            <div class="container-xxl py-5">
             <div class="container">
              <div class="row g-4">
               <div className='col-4 productimagediv'>
                   <img className='brochureproductimg1' src={certificate} alt="" />
                </div>

                <div className='col-4 productimagediv'>
                   <img className='brochureproductimg1' src={certificate} alt="" />
                </div>

                <div className='col-4 productimagediv'>
                   <img className='brochureproductimg1' src={certificate} alt="" />
                </div>

              </div>
             </div>
            </div>

           <div className="brochurefooterbottom">
             <div className="brochurefooterbtmdiv">
              <div className="brochureaddressdiv">
                <h4>
                  <i className="fa fa-map-marker-alt me-3"></i>
                  {address}
                </h4>
              </div>

              <div className="brochureaddressdiv">
                <h4>
                  <i className="fa fa-phone-alt me-3"></i>
                  {mobile}
                </h4>
              </div>

              <div className="brochureaddressdiv">
                <h4>
                  <i className="fa fa-envelope me-3"></i>
                  {email}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
