import chairman from "../../Assets/chairman.jpg";

export default function ChairmanMessage() {
  return (
    <>
      <section className="brochureaboutsection bg-icon">
        <div className="brochureaboutdiv">
          <div className="brochureaboutdescdiv">
            <h3 className="brochureheading">Chairman's Message</h3>
            <p>
              Welcome to BSH Agronomy Private Limited, where we are dedicated to
              pioneering excellence in agriculture. As the director, I am
              honored to lead a team committed to integrating sustainable
              practices with cutting-edge innovation. At BSH Agronomy, our core
              mission revolves around not just cultivating crops and tending
              gardens, but also fostering collaborative relationships with local
              farmers and communities. Together, we strive to achieve
              sustainable agricultural practices that enhance productivity while
              preserving our natural resources.ture.
            </p>

            <p>
              Our approach at BSH Agronomy emphasizes continuous learning and
              adaptation to the latest advancements in agronomy. Through
              rigorous research and development, we aim to implement
              technologies that optimize crop yields, improve soil health, and
              minimize environmental impact. By promoting these practices, we
              not only ensure the quality and reliability of our produce but
              also contribute positively to the broader agricultural
              ecosystem.e.
            </p>

            <p>
              Looking ahead, our vision is to expand our footprint nationally
              and globally, making meaningful contributions to food security and
              sustainable farming practices worldwide. We are committed to
              forging partnerships that drive innovation and empower local
              communities, ensuring a prosperous future for agriculture. Thank
              you for joining us on this journey as we work towards a
              sustainable and prosperous agronomic future.
            </p>

          </div>

          <div className="brochureaboutimgdiv d-flex justify-content-end">
            <img src={chairman} alt="" />
          </div>
        </div>
      </section>
    </>
  );
}
