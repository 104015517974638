import './BrochureProducts.css';
import FetchData from '../../Utils/FetchData';
export default function BrochureProducts() {
  const product = FetchData("Product");
  console.log("Product Data",product);
  return (
    <>
      <div class="container-xxl py-5 bg-icon">
        <div class="container">
             <div className="brochureproductheadingdiv">
                <h1>Our Products</h1>
             </div>
              <div class="row g-4">
                {
                    product && product.length > 0 && 
                    product.map((product)=>(
                     <div className='col-lg-4 col-4 productimagediv'>
                        <img className='brochureproductimg' src={product.imageUrl} alt="" />
                     </div>
                    ))
                }

              </div>
        </div>
      </div>
    </>
  )
}
