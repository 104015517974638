import FetchData from "../../Utils/FetchData";
import ProductDetailSection from "../ProductDetailSection/ProductDetailSection";
export default function BrochureProductDetails() {
  const product = FetchData("Product");
  console.log("Product Data",product);

  return (
    <>
    {
        product && product.length > 0 &&
        product.map((product)=>(
            <ProductDetailSection product={product}/>
        ))
    }
      
    </>
  )
}
