import { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { createBrowserRouter,Outlet,RouterProvider } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import MobileViewHeader from './Components/MobileViewHeader/MobileViewHeader'
import Contact from './Pages/Contact/Contact';
import Product from './Pages/Product/Product';
import NotFound from './Pages/NotFound/NotFound';
import About from './Pages/About/About';
import Register from './Pages/Register/Register';
import Login from './Pages/Login/Login';
import Upload from './Pages/Upload/Upload';
import ProductDetail from './Pages/ProductDetails/ProductDetail';
import Brochure from './Pages/Brochure/Brochure';

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="App">
      <RouterProvider router = {router}/>
    </div>
  );
}

const Layout = () => {
  return (
    <>
      <Header/>
      <Outlet />
      <Footer/>
      <MobileViewHeader/>
    </>
  );
};

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home/>,
      },
      {
        path: "/about",
        element: <About/>,
      },
      {
        path: "/contact",
        element: <Contact/>,
      },
      {
        path: "/products",
        element: <Product/>,
      },
      {
        path: "/product-detail/:id",
        element: <ProductDetail/>,
      },
      {
        path: "/register",
        element: <Register/>,
      },
      {
        path: "/login",
        element: <Login/>,
      },
      {
        path: "/upload",
        element: <Upload/>,
      },
      {
        path: "/brochure",
        element: <Brochure/>,
      },
    ],
    errorElement:<NotFound/>
  }
];

const router = createBrowserRouter(routes);

export default App;
