import './CounterCard.css';
// import icon1 from '../../Assets/gardener.png';
import CountUp from 'react-countup';

export default function CounterCard({counter,CounterOn}) {
  return (
    <>
      <div className="col-lg-2 col-6" data-aos="fade-up" data-aos-delay="100">
        <div className="stats-item d-flex flex-column align-items-center justify-content-center gap-lg-4 gap-2 w-100 h-100">
          <img class="counterImage img-fluid " src={counter.image} alt="" />
          <div>
            <h1><span className="purecounter">{CounterOn && <CountUp start={0} end={counter.count} duration={2} delay={0} />}+</span></h1>
            <p>{counter.title}</p>
          </div>
        </div>
      </div>
    </>
  );
}
