import { useState, useEffect, useRef } from "react";
import "./HeroSection.css";
import fetchData from '../../Utils/FetchData';
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import scrolldownimg from '../../Assets/scroll-down.svg';
import { IoIosContacts } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import videobg from '../../Assets/video-bg.png';
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { mobile, mobile2 } from "../../Utils/StaticData";

export default function HeroSection() {
  const [model, setModel] = useState(false);
  const video = fetchData("HeroSection");

  const iframeRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (model && iframeRef.current) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        playerRef.current = new window.YT.Player(iframeRef.current, {
          events: {
            onReady: () => {
              // Do something when the player is ready
            }
          }
        });
      };
    }
  }, [model]);

  const closeModal = () => {
    if (playerRef.current && typeof playerRef.current.pauseVideo === 'function') {
      playerRef.current.pauseVideo();
    }
    setModel(false);
  };

  return (
    <>
      <section>
        <div className={model ? "model open" : "model"}>
          <iframe
            ref={iframeRef}
            className="youtubeVideo"
            src="https://www.youtube.com/embed/O0BygutNkmw?si=kJxhUXInywRPUPEI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          <button className='modelcloseButton' onClick={closeModal}>
            <IoMdClose />
          </button>
        </div>

        <div className="carousel-header">
          <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                {
                  video && video[0] && <video autoPlay={true} loop={true} muted={true}>
                    <source src={video[0].imageUrl} type="video/mp4" />
                  </video>
                }
                <div className="carousel-caption">
                  <div className="pt-lg-4 pt-5 px-3" style={{ maxWidth: "900px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p className="perfecttext text-white text-uppercase mb-4"> PERFECT FOR YOU</p>
                    <h1 className="carouselheading mb-4" style={{ fontWeight: "600" }}>
                      Agriculture
                    </h1>
                    <h1 className="carouselheading mb-4">
                      Full Of Possibility
                    </h1>
                    <div className="d-flex align-items-center justify-content-center">
                      <Link className="herosectionbtn btn rounded-pill text-white py-3 px-5" to="/contact">Contact Us <MdArrowOutward />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="herosectionbottom">
          <div className="reachoutdiv">
            <div className="reactoutoptiondiv1">
              <IoIosContacts />
            </div>
            <div className="reactoutoptiondiv2">
              <h5>Any questions? Reach us at</h5>
              <h5>{mobile}, {mobile2}</h5>
              <h5>- Toll free</h5>
            </div>
          </div>

          <a href="#aboutuscontainer">
            <img className="scrolldownimg" src={scrolldownimg} alt="" />
          </a>

          <div className="reachoutdiv reactoutdiv2">
            <div className="reactoutoptiondiv2">
              <h5>Agriculture Matters to </h5>
              <h5>the Future of Development</h5>
            </div>

            <div className="videoimagediv" onClick={() => { setModel(true) }}>
              <img src={videobg} alt="video" />
              <MdOutlineSlowMotionVideo className="videoicon" />
            </div>
          </div>

        </div>
      </section>
    </>
  );
}
