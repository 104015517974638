import './BrochureHeader.css';
import logo from '../../Assets/BSH AGRONOMY LOGO PNG.png';
import quality from '../../Assets/quality.png'
import simple from '../../Assets/simple.png';
import natural from '../../Assets/natural.png';
import effective from '../../Assets/effective.png';

export default function BrochureHeader() {
  return (
    <>
      <section className='brochureheadersection'>
        <div className="herosectiondiv">
            <div className="col-sm-12 col-lg-12 herosectioninnerdiv" >
                <div className="brochurelogodiv" style={{backgroundColor:"white"}}>
                <img className='brochurelogo' src={logo} alt="" />
                </div>
                <img className='qualityImage' src={quality} alt="" />
            </div>

            <div className="col-sm-12 col-lg-12 herosectioninnerdiv1">
                <div className="herobottomimgdiv">
                  <img className='simpleImage' src={simple} alt="" />
                  <img className='simpleImage' src={natural} alt="" />
                  <img className='simpleImage' src={effective} alt="" />
                </div>
                
                <div className='herobottomheadingdiv'>
                    <h1 className='herosectionheading'>हर किसान अनमोल</h1>
                </div>
            </div>
            
        </div> 
      </section>
    </>
  )
}
