import { useState,useRef,useEffect } from "react";
import "./ProductCard.css";
import { IoMdClose } from "react-icons/io";
import BuyNowForm from "../BuyNowForm/BuyNowForm";

export default function ProductCard({product}) {
  const[model,setmodel]=useState(false);
  const modelRef = useRef(null); 

  const openModel = ()=>{
    setmodel(!model);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modelRef.current && !modelRef.current.contains(event.target)) {
        setmodel(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [modelRef]);

  return (
    <>
      <div className={model?"model open":"model"}>
             <BuyNowForm ref={modelRef}/>
             <button className='modelcloseButton' onClick={()=>{setmodel(false)}}><IoMdClose/></button>
      </div>  

      <div class="col-xl-4 col-lg-4 col-md-6 productcard" data-aos="fade-up" data-aos-delay="100">
        <div class="product-item">

          <div class="product-item1 position-relative bg-light overflow-hidden">
            <img class="img-fluid w-100" src={product.imageUrl} alt="" />
            {/* <div class="newbtn rounded text-white position-absolute start-0 top-0 m-lg-4 m-2 py-1 px-lg-3 px-2" style={{background: "var(--primary)"}} >
              New
            </div> */}
          </div>

        <div className="product-item2"> 
          
          <div class="text-center p-4 productnamediv">
            <p class="h5 mb-2 productname">
              {product.data.name}
            </p>
            {/* <span class="text-dark me-1">$19.00</span>
            <span class="text-body text-decoration-line-through">$29.00</span> */}
          </div>

          <div class="d-flex border-top">
            <small class="w-50 text-center border-end py-2">
              <a class="text-body" href={`/product-detail/${product.id}`}>
                <i class="fa fa-eye me-2" style={{color: "var(--primary)"}} ></i>View detail
              </a>
            </small>
            <small class="w-50 text-center py-2">
              <button class="text-body" onClick={openModel}>
                <i class="fa fa-shopping-bag me-2" style={{color: "var(--primary)"}} ></i>Buy Now
              </button>
            </small>
          </div>
        </div>

        </div>
      </div>
    </>
  );
}
