import './AlternativeService.css';
import ReadMore from '../ReadMore/ReadMore';
import dealImage from '../../Assets/dealimage.jpg';

export default function AlternativeService() {
  return (
    <>
     <section id="alt-services-2" class="alt-services">
      <div class="container" data-aos="fade-up">

        <div class="alternativeservicesrow row justify-content-around gy-4">
          <div class="alternativeservicescol1 col-lg-5"> 
            <h3>How People can deal with us?</h3>
            <p>At BSH Agronomy Pvt. Ltd., we prioritize seamless and effective engagement with our clients
               through two primary channels: Business-to-Consumer (B2C) and Business-to-Business
               (B2B). Here's a detailed look at how you can interact with us:</p>

            <div class="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
              <i class="bi bi-easel flex-shrink-0"></i>
              <div>
                <h4>B2C(Business-to-Consumer)</h4>
                <p>In our B2C model, we cater directly to farmers who seek to improve their agricultural
                   practices with our high-quality products. Here's how you can engage with us:
                </p>
                <ul style={{paddingLeft:"1rem",marginBottom:"0"}}>
                  <li><p><span style={{fontWeight:"700"}}>Direct Purchase: </span>Farmers can visit any of our company branches to explore and purchase
                         our products directly. Our knowledgeable staff is always ready to assist you in selecting the
                         right products to meet your needs.</p>
                  </li>
                </ul>
                <ReadMore>
                 <ul style={{paddingLeft:"1rem",marginBottom:"0"}}>
                 <li><p><span style={{fontWeight:"700"}}>Consultation Services:</span>If you're unsure about which products would best suit your
                        requirements, our executives are available for consultations. You can reach out to us via
                        phone or email, and our experts will guide you through our offerings and help you make an
                        informed decision.</p>
                  </li>
                  <li><p><span style={{fontWeight:"700"}}>Local Events and Demonstrations:</span>We frequently participate in local agricultural events and
                       conduct product demonstrations. These events are excellent opportunities for farmers to see
                       our products in action and ask questions directly to our representatives.</p>
                  </li>
                  </ul>
                </ReadMore>
              </div>
            </div>

            <div class="icon-box d-flex position-relative mb-4" data-aos="fade-up" data-aos-delay="200">
              <i class="bi bi-patch-check flex-shrink-0"></i>
              <div>
                <h4>B2B(Business-to-Business)</h4>
                <p>Our B2B model is designed for businesses that wish to incorporate our products into their
                   own offerings. Here's how you can engage with us in this capacity:
                </p>
                <ul style={{paddingLeft:"1rem",marginBottom:"0"}}>
                  <li><p><span style={{fontWeight:"700"}}>Partnership Opportunities:</span>We offer partnership programs where businesses can sell our
                       products through their own distribution channels. This allows our partners to expand their 
                       product lines and increase their revenue streams.</p>
                  </li>
                </ul>
                <ReadMore>
                 <ul style={{paddingLeft:"1rem",marginBottom:"0"}}>
                 <li><p><span style={{fontWeight:"700"}}>Comprehensive Support:</span>As a partner, you’ll benefit from our comprehensive support
                     services. We handle all the necessary paperwork, logistics, and regulatory compliance,
                     ensuring a smooth process from start to finish.</p>
                  </li>
                  <li><p><span style={{fontWeight:"700"}}>Training and Resources:</span>We provide extensive training and resources to our partners,
                     ensuring they are well-equipped to market and sell our products effectively. This includes
                     marketing materials, product information, and sales strategies.</p>
                  </li>
                  <li><p><span style={{fontWeight:"700"}}>Exclusive Access:</span>Partners gain exclusive access to our latest products and innovations. We
                       keep our partners informed about new developments and provide opportunities to be the
                       first to introduce these products to their customers.</p>
                  </li>
                  </ul>
                </ReadMore>
              </div>
            </div>

            <p className='mt-4'>Engaging with BSH Agronomy Pvt. Ltd. means you have access to a wealth of resources and
              support, whether you're a farmer looking to improve your yield or a business seeking
              profitable partnership opportunities. We are committed to ensuring a hassle-free experience
              that allows you to focus on growth and success.
            </p>

          </div>

          <div class="col-lg-6 " data-aos="zoom-in" data-aos-delay="100">
            <img className='dealImage' src={dealImage} alt="" />
          </div>
        </div>

      </div>
    </section>
    </>
  )
}
