import "./AboutPage.css";
import about from "../../Assets/aboutus-image.jpg";
import icon1 from "../../Assets/icon-1.png";
import { FaBuildingWheat } from "react-icons/fa6";
import { TbTruckDelivery } from "react-icons/tb";

export default function AboutPage() {
  return (
    <>
      <div class="container-xxxl py-5" id="aboutuscontainer" style={{display:"flex",justifyContent:"center"}}>
        <div class="aboutuspagerow row g-5 align-items-center">
          <div class="col-lg-6 aboutuspagecolumn1" data-aos="zoom-in" data-aos-delay="100">
            <img class="w-100 aboutuspageimage" src={about} alt="about" />
            <div className="slogandiv">
                {/* <h1>BSH</h1> */}
                <h1>हर किसान अनमोल</h1>
            </div>
          </div>

          <div class="aboutpagecolumn2 col-lg-6">
            <div className="whoarewediv" style={{ display: "flex" }}>
              <img src={icon1} alt="" />
              <p>About Us</p>
            </div>

            <h3
              class="mb-4"
              style={{ fontWeight: "700", width: "100%", fontSize: "2rem" }}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Welcome to BSH Agronomy, where agriculture meets prosperity.
            </h3>

            <p class="mb-4">
              Bsh Agronomy Private Limited is a Private incorporated on 30
              October 2020. It is classified as Non-govt company and is
              registered at Registrar of Companies, Ahmedabad. It is inolved in
              Growing of crops, market gardening, and horticulture.
            </p>

            <div className="aboutpagecontentdiv">
              <div className="aboutpageoptionsdiv">
                <div className="aboutpageoptiondiv1">
                  <FaBuildingWheat />
                </div>

                <div className="aboutpageoptiondiv2">
                  <h5 style={{ width: "100%", fontWeight: "700" }}>
                    Infrastructure
                  </h5>
                  <p style={{ textAlign: "justify",width:"100%" }}>
                    Our state-of-the-art infrastructure includes advanced
                    manufacturing facilities, research and development labs, and
                    efficient supply chain systems. These modern amenities allow
                    us to produce high-quality products, conduct innovative
                    research, and deliver our solutions swiftly and reliably to
                    farmers across the globe. 
                  </p>
                </div>
              </div>

              <div className="aboutpageoptionsdiv">
                <div className="aboutpageoptiondiv1">
                  <TbTruckDelivery />
                </div>

                <div className="aboutpageoptiondiv2">
                  <h5 style={{ width: "100%", fontWeight: "700" }}>
                    Farmer and Distributor Network
                  </h5>
                  <p style={{ textAlign: "justify" }}>
                    We have established an extensive network of farmers and
                    distributors network who rely on our products. By
                    collaborating closely with farmers, we understand their
                    unique needs and challenges, allowing us to provide tailored
                    solutions that enhance crop health and yield.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
