import React from 'react'
import Top from '../../Components/Top/Top'
import Counter from '../../Components/Counter/Counter'
import AlternativeService from '../../Components/AlternativeService/AlternativeService'
import AboutPage from '../../Components/AboutPage/AboutPage'

export default function About() {
  return (
    <>
      <Top heading = "About Us"/>
      <AboutPage/>
      <Counter/>
      <AlternativeService/>
    </>
  )
}
