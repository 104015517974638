import { useState } from "react";
import './Contact.css'
import Top from "../../Components/Top/Top";
import ContactForm from '../../Utils/ContactForm';
import { email as contectEmail,address, facebook, instagram, linkedin, mobile, twitter, mobile2 } from "../../Utils/StaticData";

export default function Contact() {
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState({});
    
    const [errors, setErrors] = useState({});
  
    const [name,setName] = useState('');
    const [email,setEmail] = useState("");
    const [number,setNumber] = useState('');
    const [message,setMessage] = useState('');
  
    const validation = () => {
      const newErrors = {};
      const emailPattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
      const phonePattern = /^[\d+\-\s()]*$/;
    
    
      if (!name) {
        newErrors.name = 'Name is required';
      }
    
      if (!number) {
        newErrors.number = 'Mobile Number is required';
      } else if (!phonePattern.test(number)) {
        newErrors.number = 'Please enter correct Correct Mobile Number';
      }
    
      if (!email) {
        newErrors.email = 'Email is required';
      } else if (!emailPattern.test(email)) {
        newErrors.email = 'Please enter Correct Email ID';
      }
    
      if (!message) {
        newErrors.message = 'Message is required';
      }
    
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
    
    const submitContactUsForm = async (e) =>{
      e.preventDefault();
  
      if(validation()){
          setLoading(true);
          setLoadingMessage({ type: 'wait', text:"Wait Until Your Data is Submitted!..."});
  
          const {type,text,loading} = await ContactForm({name,email,number,message});
  
          setLoadingMessage({ type: type, text: text });
          setTimeout(() => {
            setLoading(loading);
          }, 5000);
  
          setName("");
          setEmail("");
          setNumber("");
          setMessage("");
      }
      // else{
      //   alert("Please fill the correct information.")
      // }
    }

  return (
      <>
    <Top heading = "Contact Us"/>
    <div className="container-xxl" style={{marginTop:"50px",marginBottom:"50px"}}>
      <div className="container">
          {/* <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxwidth: "500px"}}>
              <h1 className="display-5 mb-3" style={{fontWeight:"700"}}>Contact Us</h1>
          </div> */}
          <div className="row g-5 justify-content-center">
              <div className="col-lg-5 col-md-12" data-aos="fade-up">
                  <div className="text-white d-flex flex-column justify-content-center h-100 p-5" style={{background: "var(--primary)"}}>
                      <h5 className="text-white">Call Us</h5> 
                      <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>{mobile}</p>
                      <p className="mb-5"><i className="fa fa-phone-alt me-3"></i>{mobile2}</p>
                      <h5 className="text-white">Email Us</h5>
                      <p className="mb-5"><i className="fa fa-envelope me-3"></i>{contectEmail}</p>
                      <h5 className="text-white">Office Address</h5>
                      <p className="mb-5"><i className="fa fa-map-marker-alt me-3"></i>{address}</p>
                      <h5 className="text-white">Follow Us</h5>
                      <div className="d-flex pt-2">
                          <a className="btn btn-square btn-outline-light rounded-circle me-1" href={twitter}><i className="fab fa-twitter"></i></a>
                          <a className="btn btn-square btn-outline-light rounded-circle me-1" href={facebook}><i className="fab fa-facebook-f"></i></a>
                          <a className="btn btn-square btn-outline-light rounded-circle me-1" href={instagram}><i className="fab fa-instagram"></i></a>
                          <a className="btn btn-square btn-outline-light rounded-circle me-0" href={linkedin}><i className="fab fa-linkedin-in"></i></a>
                      </div>
                  </div>
              </div>
              <div className="col-lg-7 col-md-12" data-aos="fade-up">
                  <form>
                      <div className="row g-3">
                          <div className="col-md-6">
                              <div className="form-floating">
                                  <input type="text" className="form-control" id="name" placeholder="Your Name" required
                                   value={name} onChange={(e)=>{setName(e.target.value)}}/>
                                  <label for="name">Your Name</label>
                                  {errors.name && (<span className="contactspan">{errors.name}</span>)}
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-floating">
                                  <input type="email" className="form-control" id="email" placeholder="Your Email" required
                                  value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                                  <label for="email">Your Email</label>
                                  {errors.email && (<span className="contactspan">{errors.email}</span>)}
                              </div>
                          </div>
                          <div className="col-12">
                              <div className="form-floating">
                                  <input type="text" className="form-control" id="number" placeholder="Number" required
                                  value={number} onChange={(e)=>{setNumber(e.target.value)}}/>
                                  <label for="number">Number</label>
                                  {errors.number && (<span className="contactspan">{errors.number}</span>)}
                              </div>
                          </div>
                          <div className="col-12">
                              <div className="form-floating">
                                  <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: "200px"}}
                                  value={message} onChange={(e)=>{setMessage(e.target.value)}}></textarea>
                                  <label for="message">Message</label>
                                  {errors.message && (<span className="contactspan">{errors.message}</span>)}
                              </div>
                          </div>
                          <div className="col-12 text-center">
                            {
                            loading ? 
                            <p style={{ color: loadingMessage.type === 'success' ? 'green' : loadingMessage.type === 'error' ? 'red' :'yellow'}}>{loadingMessage.text}</p>
                            :
                            <button className="btn text-white rounded-pill py-3 px-5" style={{background: "var(--primary)"}} onClick={submitContactUsForm} type="submit">Send Message</button>
                            }
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>

  <div class="container-xxl px-0" data-aos="fade-up" style={{marginbottom: "-6px"}}>
       <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d29692.297909277328!2d72.9810503760344!3d21.52583870861938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPlot.%20No.142%2C%20Nana%20Borsara%20Road%2C%20Tal.%20Mangrol%2C%20Dist.%20Surat%20394125!5e0!3m2!1sen!2sin!4v1719805800241!5m2!1sen!2sin" 
       width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
  </>
     
  )
}