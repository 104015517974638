import './Footer.css';
import logoimage from '../../Assets/BSH AGRONOMY LOGO PNG.png';
import { Link } from 'react-router-dom';

import { address, linkedin, mobile2 } from '../../Utils/StaticData';
import { mobile } from '../../Utils/StaticData';
import { email } from '../../Utils/StaticData';


import { facebook } from '../../Utils/StaticData';
import { instagram } from '../../Utils/StaticData';
import { twitter } from '../../Utils/StaticData';

import { FaFilePdf } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa6";
const Footer = () => {

    return (
        <div className="container-fluid bg-dark footer pt-5">
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                    <div className="logodiv">
                      <Link to="/"><img className="footerlogo" src={logoimage} alt='logo'/></Link> 
                    </div> 
                    <p> Bsh Agronomy Private Limited is a Private incorporated on 30
                        October 2020. It is classified as Non-govt company and is
                        registered at Registrar of Companies, Ahmedabad.
                        It is inolved in Growing of crops, market gardening,
                        and horticulture.
                    </p>
                    <div className="d-flex pt-2">
                        <a className="btn btn-square btn-outline-light rounded-circle me-1" href={twitter}><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-square btn-outline-light rounded-circle me-1" href={facebook}><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-square btn-outline-light rounded-circle me-1" href={instagram}><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-square btn-outline-light rounded-circle me-0" href={linkedin}><i className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Address</h4>
                    <p><i className="fa fa-map-marker-alt me-3"></i>{address}</p>
                    <p><i className="fa fa-phone-alt me-3"></i>{mobile}</p>
                    <p><i className="fa fa-phone-alt me-3"></i>{mobile2}</p>
                    <p><i className="fa fa-envelope me-3"></i>{email}</p>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Quick Links</h4>
                    <Link className="btn btn-link" to="/">Home</Link>
                    <Link className="btn btn-link" to="/about">About</Link>
                    <Link className="btn btn-link" to="/contact">Contact</Link>
                    <Link className="btn btn-link" to="/products">Products</Link>
                    <Link className="btn btn-link" to="/">Terms & Condition</Link>
                    <Link className="btn btn-link" to="/">Privacy Policy</Link>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-light mb-4">Newsletter</h4>
                    <p>Subscribe to our Newsletter for latest updates.</p>
                    <div className="position-relative mx-auto mb-4" style={{maxwidth: "400px"}}>
                        <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5 text-white" type="text" placeholder="Your email" style={{color:"white"}}/>
                        <button type="button" className="btn py-2 text-white position-absolute top-0 end-0 mt-2 me-2" style={{background: "var(--primary)"}} >SignUp</button>
                    </div>

                    <div className="downloadbrochurediv mx-auto" style={{maxwidth: "400px"}}>
                      <div className='svgdiv'>
                       <FaFilePdf/> Download Brochure
                      </div>
                     
                      <div className="downloaddiv">
                      <FaArrowDown/>
                     </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="container-fluid copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; Copyright <Link to="/">BSH Agronomy</Link> All Right Reserved.
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        Designed By <a href="https://www.glowtechmor.com/">Glowtechmor PVT. LTD</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;