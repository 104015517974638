import "./AboutUs.css";
import { GiPlantRoots } from "react-icons/gi";
import { PiTractorDuotone } from "react-icons/pi";
import about from "../../Assets/home-image.jpg";
import icon1 from '../../Assets/icon-1.png';
import aboutusinner from '../../Assets/aboutusinner.jpg';

export default function AboutUs() {
  return (
    <>
      <div class="container-xxxl py-5" id="aboutuscontainer" style={{backgroundColor:"#f8f7f0"}}>
        <div class="container">
          <div class="aboutusrow row g-5 align-items-center">

            <div class="col-lg-6 aboutuscolumn1" data-aos="zoom-in" data-aos-delay="100">
                <img class="w-100" src={about} alt="about" />
            </div>

            <div class="col-lg-6 aboutuscolumn2">
              <div className="whoarewediv" style={{display:"flex"}}>
              <img  src={icon1} alt="" /><p>Who We Are</p>
              </div>
          
              <h1 class="display-5 mb-4" style={{ fontWeight: "700" }} data-aos="fade-up" data-aos-delay="100">
                Better Agriculture for Better Future
              </h1>

              <div className="aboutcontentdiv">
                <p class="mb-4">
                Bsh Agronomy Private Limited is a Private incorporated on 30
                October 2020. It is classified as Non-govt company and is
                registered at Registrar of Companies, Ahmedabad.
                It is inolved in Growing of crops, market gardening,
                and horticulture.
                </p>

                <div className="aboutoptionsdiv">
                  <div className="aboutoptiondiv1">
                    <GiPlantRoots/>
                  </div>

                  <div className="aboutoptiondiv2">
                    <h4>Organic & Eco Solutions</h4>
                    <p style={{textAlign:"justify"}}>Our commitment to organic and eco-friendly solutions ensures the health of your soil and   
                      the quality of your crops. We provide cutting-edge, sustainable practices to enhance soil fertility and crop yeild.
                    </p>
                  </div>
                </div>

                <div className="aboutoptionsdiv">
                  <div className="aboutoptiondiv1">
                    <PiTractorDuotone/>
                  </div>

                  <div className="aboutoptiondiv2">
                    <h4>Professional Team</h4>
                    <p style={{textAlign:"justify"}}>Our dedicated professional team, equipped with extensive experties and hands-on experience in the agriculture sector,
                      is supported by a state-of-the-art high tech-lab. We collaborate with farmers to ensure optimal crop production and promote 
                      sustainable farming practices. </p>
                  </div>
                </div>

                <img style={{width:"100%"}} src={aboutusinner} alt="" />

                <h4>We're Committed to Caring.</h4>

              <div className="aboutuscontentuldiv">
               <ul>
                <li> 
                 <p><i class="fa fa-check textprimary me-3"></i>Unmatched Quality Standards</p>
                </li>

                <li> 
                 <p><i class="fa fa-check textprimary me-3"></i>Efficient Fertilizer Distribution</p>
                </li>

                <li> 
                 <p><i class="fa fa-check textprimary me-3"></i>Growing Together</p>
                </li>
               </ul>

               <ul>
                <li> 
                  <p><i class="fa fa-check textprimary me-3"></i>Organic & Bio Fertilizer</p>
                </li>
                
                <li>
                 <p><i class="fa fa-check textprimary me-3"></i>Empowering Farmers</p>
                </li>

                <li>
                 <p><i class="fa fa-check textprimary me-3"></i>Enriching Soil, Enriching Lives</p>
                </li>
               </ul>
              </div>
             </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
